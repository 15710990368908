import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
  Text,
} from "@chakra-ui/react";

export const MaintenanceServices = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h1"} fontSize="xl">
          Comprehensive Maintenance Services for Santa Monica Properties
        </Heading>
        <Text>
          At Santa Monica Property Management, we deliver an unparalleled
          maintenance service that underscores our dedication to property care.
          Recognizing the pivotal role of well-maintained properties in
          preserving and enhancing their value, we go above and beyond to ensure
          meticulous care for your investment.
        </Text>
        <Text>
          Our devoted maintenance team, consisting of seasoned professionals, is
          available 24/7 to address both routine and unforeseen property needs.
          We seamlessly blend expertise, efficiency, and meticulous attention to
          detail to keep your property in impeccable condition.
        </Text>
        <Heading as={"h2"} fontSize="lg">
          Our Comprehensive Maintenance Solutions
        </Heading>
        <Text>
          Acknowledging that maintenance goes beyond fixing issues—it's about
          preventative care and elevating the property's appeal—we proudly
          present an overview of the maintenance services we offer:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Thorough Regular Inspections:</b> Proactively scheduling regular
            inspections to detect potential issues early, averting costly
            repairs and extending the life of your property.
          </ListItem>
          <ListItem>
            <b>Responsive Emergency Repairs:</b> Equipping our responsive
            maintenance team to handle emergency repairs promptly, ensuring the
            safety of both property and tenants from unforeseen issues.
          </ListItem>
          <ListItem>
            <b>Assured Quality Work:</b> Ensuring our work meets the highest
            standards of quality, with meticulous attention to detail in
            completing all maintenance tasks flawlessly.
          </ListItem>
          <ListItem>
            <b>Seamless Vendor Management:</b> Overseeing all interactions with
            third-party vendors, from negotiation to job completion, to ensure
            efficient work aligned with our quality expectations.
          </ListItem>
        </UnorderedList>
        <Text>
          We extend our maintenance services to include landscaping,
          renovations, and modernizations, enhancing curb appeal and property
          functionality. By choosing Santa Monica Property Management, you opt
          for a partner that invests in your property’s longevity and
          desirability.
        </Text>
        <Text>
          Our maintenance approach is tailored to the unique requirements of
          your property—whether it's a historical building requiring specialized
          care, a contemporary complex demanding the latest in property
          technology, or anything in between, our team is equipped and ready.
        </Text>
        <Text>
          Trust Santa Monica Property Management for all-encompassing
          maintenance services that protect, improve, and elevate your property
          investment. Experience the peace of mind that comes with knowing your
          property is in expert hands.
        </Text>
      </Stack>
    </Box>
  );
};
