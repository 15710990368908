import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const EmergencyResponse = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"} size="lg">
          24/7 Emergency Assistance You Can Rely On
        </Heading>
        <Text>
          At Santa Monica Property Management, we recognize that emergencies
          don't wait, and precise timing is essential. That's why we provide
          comprehensive 24/7 emergency assistance, ensuring that when the
          unexpected occurs, immediate action is taken to protect both your
          property and your tenants.
        </Text>
        <Text>
          Our emergency response team is not only highly trained but also
          equipped to handle a diverse range of urgent scenarios with
          unparalleled speed, efficiency, and expertise. Whether faced with
          burst pipes in the middle of the night or unexpected power outages
          during a holiday, our dedicated approach is specifically crafted to
          swiftly address and resolve issues. This proactive strategy not only
          minimizes potential damage to your property but also ensures minimal
          disruption for tenants, showcasing our commitment to maintaining a
          secure and comfortable living environment at all times.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Immediate Response:</b> Our team is available around the clock,
            ready to respond the moment an emergency call comes in. This ensures
            that critical situations are addressed promptly and effectively,
            maintaining the safety and well-being of your property and its
            occupants.
          </ListItem>
          <ListItem>
            <b>Qualified Professionals:</b> Proud of our team of highly trained
            experts, we excel in managing and resolving all types of property
            emergencies with precision and care. Their expertise ensures swift
            and effective responses, safeguarding your property and the
            well-being of its occupants in any unexpected situation.
          </ListItem>
          <ListItem>
            <b>Effective Resource Deployment:</b> In emergencies, having the
            right resources and contacts is crucial. We guarantee the swift
            mobilization of personnel and equipment, ensuring the efficient
            handling of any crisis. This proactive approach contributes to
            minimizing potential damages and maintaining the security of your
            property.
          </ListItem>
          <ListItem>
            <b>Peace of Mind Assurance:</b> Ensuring your property's security
            around the clock provides both you and your tenants the peace of
            mind you deserve. Our unwavering commitment is to your safety and
            comfort, ensuring a secure living environment every hour of every
            day.
          </ListItem>
        </UnorderedList>
        <Text>
          Opt for Santa Monica Property Management for unmatched emergency
          support. We undertake the responsibility of shielding your investment
          from the unpredictability of emergencies, allowing you to rest easy,
          confident that we have everything under control, always.
        </Text>
      </Stack>
    </Box>
  );
};
