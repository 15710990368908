import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const LegalServices = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"} size="lg">
          Comprehensive Financial Reporting Services
        </Heading>
        <Text>
          Santa Monica Property Management is dedicated to providing property
          owners with thorough financial transparency. Our meticulous financial
          reporting services offer a panoramic view of your property's financial
          performance, enabling you to make informed decisions based on concrete
          data.
        </Text>
        <Text>
          Valuing the fiscal health of your investment, we implement rigorous
          accounting practices that depict the complete financial landscape of
          your property. From the monthly ebb and flow of expenses to annual
          revenue streams, our reports capture every detail with precision and
          clarity. This meticulous approach ensures that property owners have a
          comprehensive understanding of their investment's financial
          performance.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Monthly Financial Statements:</b> Enjoy the convenience of
            receiving itemized monthly statements that detail both income and
            expenditures. Our easy-to-understand reports are meticulously
            crafted to keep you well-informed about your property's financial
            activity. This transparency ensures property owners have a clear and
            accessible overview of their investment's financial performance.
          </ListItem>
          <ListItem>
            <b>Comprehensive Tax Guidance:</b> Simplify tax preparation with our
            year-end financial summaries. Offering a clear overview of the
            year's finances, we make tax filing a less daunting task for you or
            your accountant. Our comprehensive summaries provide the necessary
            information, ensuring a smooth and efficient tax preparation process
            for property owners.
          </ListItem>
          <ListItem>
            <b>Expenditure Tracking:</b> With a commitment to transparency, we
            track every penny meticulously. Our expenditure reports offer a full
            account of maintenance costs, capital improvements, and operational
            expenses, ensuring you're never in the dark about your investments.
            This detailed financial insight empowers property owners with the
            knowledge needed to make informed decisions and optimize the
            financial performance of their assets.
          </ListItem>
          <ListItem>
            <b>Revenue Optimization Strategies:</b> With a deep understanding of
            Santa Monica's property market, we offer actionable insights to
            enhance your property's profitability. Identifying trends and
            opportunities, our expertise enables you to maximize your
            investment's earning potential. Stay ahead in the dynamic real
            estate landscape with our informed strategies tailored to optimize
            returns for your property.
          </ListItem>
        </UnorderedList>
        <Text>
          Choose Santa Monica Property Management for financial reporting
          services that not only keep you informed about your property's
          performance but also empower you with the knowledge to enhance your
          investment's value. Contact us today to experience financial reporting
          that equips you with the insights necessary for informed property
          management decisions.
        </Text>
      </Stack>
    </Box>
  );
};
