import React from "react";
import {SiteData} from "../../Constants/siteData";
import {PageWrapper} from "../../Components/PageWrapper/PageWrapper";
import PageHeader from "../../Components/common/PageHeader";
import ServicePageTemplate from "../../Containers/ServiceContainers/ServicePageTemplate";
import {SEO} from "../../Components/SEO/SEO";

export default function PropertyManagementBeverlyHills() {
    return (
        <PageWrapper>
            <SEO/>
            <PageHeader header={SiteData.beverlyHills.title} subtitle={SiteData.beverlyHills.subtitle}/>
            <ServicePageTemplate body={SiteData.beverlyHills.body}  src={SiteData.beverlyHills.src}/>
        </PageWrapper>
    )
}