import React from "react";
import {SiteData} from "../../Constants/siteData";
import {PageWrapper} from "../../Components/PageWrapper/PageWrapper";
import PageHeader from "../../Components/common/PageHeader";
import ServicePageTemplate from "../../Containers/ServiceContainers/ServicePageTemplate";
import {SEO} from "../../Components/SEO/SEO";

export default function LosAngeles() {
    return (
        <PageWrapper>
            <SEO/>
            <PageHeader header={SiteData.losAngeles.title} subtitle={SiteData.losAngeles.subtitle}/>
            <ServicePageTemplate body={SiteData.losAngeles.body}  src={SiteData.losAngeles.src}/>
        </PageWrapper>
    )
}