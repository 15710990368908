import React from "react";
import {SiteData} from "../../Constants/siteData";
import {PageWrapper} from "../../Components/PageWrapper/PageWrapper";
import PageHeader from "../../Components/common/PageHeader";
import ServicePageTemplate from "../../Containers/ServiceContainers/ServicePageTemplate";
import {SEO} from "../../Components/SEO/SEO";

export default function KoreatownPropertyManagement() {
    return (
        <PageWrapper>
            <SEO/>
            <PageHeader header={SiteData.koreatown.title} subtitle={SiteData.koreatown.subtitle}/>
            <ServicePageTemplate body={SiteData.koreatown.body}  src={SiteData.koreatown.src}/>
        </PageWrapper>
    )
}