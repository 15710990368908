import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const TenantRelations = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h1"} fontSize="xl">
          Exceptional Tenant Relationships in Santa Monica
        </Heading>
        <Text>
          At Santa Monica Property Management, we recognize that the foundation
          of a thriving rental property lies in the relationship between tenants
          and landlords. Our commitment is to nurture this dynamic, fostering a
          stable, respectful, and mutually beneficial partnership.
        </Text>
        <Text>
          Effective tenant relations go beyond conflict resolution; they involve
          creating an environment where issues rarely arise. We adopt a
          proactive approach, addressing potential concerns before they
          escalate, thereby cultivating a sense of community and respect.
        </Text>
        <Heading as={"h2"} fontSize="lg">
          Our Approach to Tenant Relationships
        </Heading>
        <Text>
          Our philosophy revolves around the belief that content tenants
          contribute to a successful, profitable property. We prioritize
          transparent communication, swift action on issues, and a personalized
          touch that makes tenants feel valued and heard. Here’s how we achieve
          this:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Proactive Conflict Resolution:</b> In the event of disputes, we
            intervene promptly with impartiality and professionalism.
            Effectively mediating issues prevents escalation and ensures all
            parties are content with the resolution, maintaining a peaceful
            living environment.
          </ListItem>
          <ListItem>
            <b>Clear and Open Communication:</b> Keeping everyone informed is
            crucial for good relations. Whether conveying maintenance updates,
            policy changes, or simply checking in, our open lines of
            communication ensure tenants and landlords are consistently on the
            same page.
          </ListItem>
          <ListItem>
            <b>Strategies for Tenant Retention:</b> We believe in cultivating
            long-term relationships. Through outstanding service and attention
            to tenant satisfaction, we retain tenants for extended durations,
            contributing to the stable financial performance of your property.
          </ListItem>
          <ListItem>
            <b>Regular Updates and Reports:</b> Landlords are kept well-informed
            with regular updates. Whether it pertains to unit statuses,
            financial reports, or tenant feedback, we provide comprehensive
            information to keep you abreast of your property’s well-being.
          </ListItem>
        </UnorderedList>
        <Text>
          Our tenant relations services go beyond these core pillars. We conduct
          routine tenant satisfaction surveys to gauge sentiments and needs,
          organize community-building events to strengthen the tenant community,
          and implement efficiency in all tenant-facing processes, from
          onboarding to moving out.
        </Text>
        <Text>
          We recognize that a tenant's positive experience reflects the quality
          of property management. With Santa Monica Property Management, you can
          trust that your tenants are in capable hands—hands that prioritize
          their comfort and your peace of mind.
        </Text>
        <Text>
          Partner with us at Santa Monica Property Management for tenant
          relations that result in enduring value for your property investment.
          Our dedicated team is ready to showcase the positive impact that
          professional property management can make in the lives of both
          landlords and tenants.
        </Text>
      </Stack>
    </Box>
  );
};
