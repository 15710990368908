import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
  Text,
} from "@chakra-ui/react";

export const RentCollection = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"} size="lg">
          Streamlined Rent Collection Services in Santa Monica
        </Heading>
        <Text>
          Ensuring efficient rent collection is fundamental to successful
          property management, and at Santa Monica Property Management, we've
          prioritized creating a seamless, stress-free experience for both
          property owners and tenants. Employing cutting-edge property
          management technology, we've modernized and streamlined the entire
          rent collection process.
        </Text>
        <Text>
          Our state-of-the-art online portal offers tenants a user-friendly
          platform to submit their payments quickly and securely. This
          contemporary approach minimizes delays and optimizes the financial
          flow, ensuring property owners receive their funds promptly.
        </Text>
        <Heading as={"h3"} size="md">
          Elevating Rent Collection through Technology and Expertise
        </Heading>
        <Text>
          Our rent collection services are tailored with key features to promote
          on-time payments and offer property owners a comprehensive
          understanding of their property's financial status. Through a
          streamlined process, we prioritize timely payments while providing
          transparent financial insights to ensure efficient and organized
          property management.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Convenient Digital Payments:</b> With our accessible 24/7 online
            payment system, tenants can conveniently pay rent anytime, anywhere,
            eliminating the hassle of paper checks. This user-friendly platform
            enhances convenience for tenants while streamlining the rent
            collection process, ensuring a seamless and efficient payment
            experience.
          </ListItem>
          <ListItem>
            <b>Proactive Payment Reminders:</b> Our automated reminders
            significantly decrease the likelihood of late payments, guaranteeing
            a consistent income stream for property owners. This proactive
            approach helps tenants stay on track with their payments,
            contributing to a financially stable and well-managed property.
          </ListItem>
          <ListItem>
            <b>In-Depth Financial Reporting:</b> Property owners receive
            detailed monthly statements, providing transparency and tracking
            rental income and expenses. These statements offer valuable insights
            into the investment, enabling property owners to make informed
            decisions and maintain a clear overview of their financial
            portfolio. performance.
          </ListItem>
          <ListItem>
            <b>Effective Default Management:</b> In rare cases of non-payment,
            our experienced team promptly addresses the situation, prioritizing
            legal compliance and maintaining positive landlord-tenant relations.
            Our approach ensures a swift resolution while upholding
            professionalism and legal standards, fostering a harmonious
            environment for all parties involved.
          </ListItem>
        </UnorderedList>
        <Text>
          At Santa Monica Property Management, we recognize that a robust yet
          equitable rent collection process is vital for sustaining the
          profitability of your rental property. Our methods not only preserve
          the financial viability of your property but also prioritize
          accommodating the needs of your tenants, fostering a respectful and
          professional relationship.
        </Text>
        <Text>
          Whether you own a single-family home, a multi-unit apartment complex,
          or a substantial property portfolio, our rent collection services are
          scalable and adaptable to your specific needs. Allow us to relieve you
          of the rent collection burden, enabling you to enjoy the rewards of
          your investment without the associated administrative stress.
        </Text>
        <Text>
          Connect with Santa Monica Property Management today to discover how we
          can revolutionize your rent collection process and elevate your
          property management experience.
        </Text>
      </Stack>
    </Box>
  );
};
