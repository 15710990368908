import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
  Text,
} from "@chakra-ui/react";

export const MarketingAdvertising = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"} size="lg">
          Cutting-Edge Marketing & Advertising for Santa Monica Properties
        </Heading>
        <Text>
          In the competitive Santa Monica real estate market, effective
          marketing and advertising strategies are essential for attracting
          high-quality tenants. At Santa Monica Property Management, we
          seamlessly blend traditional marketing expertise with digital
          innovation to ensure your property takes center stage.
        </Text>
        <Text>
          Our expert team employs a dynamic mix of digital marketing techniques,
          specifically designed to enhance your property's online presence and
          appeal directly to your ideal tenant demographic. By utilizing the
          latest and most effective digital advertising practices, we elevate
          your property's visibility and desirability in the marketplace.
        </Text>
        <Heading as={"h3"} size="md">
          A Comprehensive Range of Marketing & Advertising Services
        </Heading>
        <Text>
          Our marketing and advertising services are all-encompassing, covering
          every aspect required to attract and secure the best tenants:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Strategic Targeted Advertising:</b> We implement targeted
            advertising campaigns that leverage demographic and geographic data
            to reach prospective tenants who will find your property most
            appealing. By tailoring our approach, we ensure that your property
            receives maximum visibility among the desired audience, increasing
            the likelihood of attracting tenants who align with your property's
            unique features and offerings.
          </ListItem>
          <ListItem>
            <b>Engaging Social Media Promotion:</b> Harnessing the immense power
            of social media, we strategically create compelling content and
            campaigns designed to generate interest and excitement around your
            property. By utilizing popular platforms, we engage with a broad
            audience, showcasing the unique features and benefits of your
            property, ultimately driving visibility and attracting potential
            tenants.
          </ListItem>
          <ListItem>
            <b>Interactive Virtual Tours:</b> We enhance the decision-making
            process for potential tenants by offering detailed, immersive
            experiences of your property through virtual tours. This innovative
            approach provides a comprehensive view of the space, allowing
            prospective tenants to explore and evaluate the property remotely,
            thereby expediting their decision-making process.
          </ListItem>
          <ListItem>
            <b>SEO-Optimized Listings:</b> We craft engaging listings optimized
            for search engines, ensuring that your property ranks highly in
            relevant searches and attracts more potential tenants. By leveraging
            effective SEO strategies, we maximize the visibility of your
            property listing, making it more accessible to individuals actively
            searching for housing, thereby increasing the likelihood of securing
            quality tenants.
          </ListItem>
          <ListItem>
            <b>Comprehensive Analytics & Reporting:</b> We offer insights into
            your property's marketing performance through in-depth analytics and
            monthly reports. These reports provide a detailed overview of the
            success of your campaigns, enabling you to track key metrics,
            understand tenant engagement, and make informed decisions to
            continually optimize and enhance your property's marketing strategy.
          </ListItem>
        </UnorderedList>
        <Text>
          Our approach to marketing and advertising goes beyond filling
          vacancies; it's about building your property's reputation and
          establishing its presence as a premier choice in Santa Monica. We
          focus on creating a strong brand for your property that resonates with
          the lifestyle and aspirations of potential tenants.
        </Text>
        <Text>
          With Santa Monica Property Management, your property benefits from
          heightened exposure, increased demand, and ultimately, a higher return
          on investment. Our tailored marketing strategies ensure that your
          property doesn't just get seen—it gets noticed by the right people.
        </Text>
        <Text>
          Seize the opportunity to leverage our marketing expertise and witness
          the transformation of your property's market presence. Contact us
          today to explore how our marketing and advertising services can help
          you achieve your property management goals.
        </Text>
      </Stack>
    </Box>
  );
};
