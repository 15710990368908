import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const FinancialReporting = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"} size="lg">
          Unveiling Comprehensive Financial Insights
        </Heading>
        <Text>
          Santa Monica Property Management is dedicated to delivering property
          owners a complete and transparent financial overview. Our in-depth
          financial reporting services provide a holistic perspective of your
          property's financial performance, empowering you with precise data for
          informed decision-making.
        </Text>
        <Text>
          Acknowledging the pivotal role of financial health in your investment,
          we employ stringent accounting practices that accurately depict the
          entire financial panorama of your property. From the monthly dynamics
          of expenses to annual revenue streams, our reports encapsulate
          everything with accuracy and transparency.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Monthly Financial Statements:</b>Experience peace of mind with
            our comprehensive monthly statements detailing income and
            expenditures. Our user-friendly reports are designed to keep you
            thoroughly informed about your property's financial dynamics,
            providing a transparent and accessible overview of your investment.
          </ListItem>
          <ListItem>
            <b>Comprehensive Tax Guidance:</b> Simplify tax preparation with our
            year-end financial summaries. Offering a clear snapshot of the
            year's finances, we strive to simplify tax filing for you or your
            accountant. Our detailed summaries provide the necessary
            information, facilitating a smooth and efficient tax preparation
            process for property owners.
          </ListItem>
          <ListItem>
            <b>Expenditure Tracking:</b> With a commitment to transparency, we
            meticulously track every financial transaction. Our expenditure
            reports offer a comprehensive account of maintenance costs, capital
            improvements, and operational expenses, ensuring transparency in
            your investment details. This detailed financial insight empowers
            property owners with the knowledge needed to make informed decisions
            and optimize the financial performance of their assets.
          </ListItem>
          <ListItem>
            <b>Revenue Optimization Strategies:</b> With our finger on the pulse
            of Santa Monica's property market, we provide actionable insights to
            enhance your property’s profitability. Identifying trends and
            opportunities, we assist you in maximizing your investment's earning
            potential. Stay ahead in the dynamic real estate landscape with our
            informed strategies tailored to optimize returns for your property.
          </ListItem>
        </UnorderedList>
        <Text>
          Align with Santa Monica Property Management for financial reporting
          services that not only keep you well-versed with your property's
          performance but also empower you with knowledge to elevate your
          investment's value. Connect with us today to experience financial
          reporting that informs and guides your property management strategies.
        </Text>
        <Text>
          We strongly believe that transparency is pivotal for a successful
          partnership. By furnishing detailed financial reports, our goal is not
          just to meet but exceed your expectations, providing you with the
          insights required to make strategic decisions for the sustained
          success of your property investment.
        </Text>
      </Stack>
    </Box>
  );
};
