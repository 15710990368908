import $ from "jquery";
import React, { useEffect } from "react";
import PageHeader from "../Components/common/PageHeader";
import Banner from "../Containers/Banner";
import { SiteData } from "../Constants/siteData";
import { Box } from "@chakra-ui/react";

const faqs = [
  {
    q: "What types of properties do you manage?",
    a: "We oversee a diverse range of properties, including residential units such as apartments and single-family homes, as well as commercial properties in Santa Monica and its surrounding areas.",
  },
  {
    q: "How do you handle tenant screening?",
    a: "Our tenant screening process is meticulous and involves comprehensive background checks, credit assessments, employment verification, and a review of previous rental history. This ensures the selection of reliable and responsible tenants.",
  },
  {
    q: "What is your approach to property maintenance?",
    a: "Our approach to property maintenance prioritizes preventive measures to keep properties in optimal condition. We promptly address repair requests and conduct regular inspections to identify and resolve issues before they escalate.",
  },
  {
    q: "How do you ensure timely rent collection?",
    a: "We implement efficient rent collection processes, utilizing online payment systems for tenant convenience. Additionally, we enforce lease agreements rigorously to ensure consistent and timely rent payments.",
  },
  {
    q: "Can you handle legal issues related to property management?",
    a: "Certainly, our team is well-versed in property laws and adept at managing legal matters, including lease agreements, tenant disputes, and evictions. This ensures compliance and safeguards your interests.",
  },
  {
    q: "Do you offer financial reporting for property owners?",
    a: "Absolutely, we provide detailed monthly financial reports comprising income and expense statements. These reports empower property owners to closely monitor and evaluate their investment's performance.",
  },
  {
    q: "What is your fee structure for property management services?",
    a: "Our fee structure is tailored based on the type and number of properties under management. We offer competitive rates and can provide a detailed quote that aligns with your specific property management needs.",
  },
  {
    q: "How do you handle emergency situations?",
    a: "We have a 24/7 emergency response system in place to address urgent maintenance issues promptly. Our team is equipped to manage emergencies efficiently, minimizing damage and disruption to the property.",
  },
  {
    q: "What sets your property management services apart from others?",
    a: "Our distinction lies in our commitment to personalized service, extensive local market knowledge, and a proactive approach to property management. We focus on maximizing returns while minimizing the hassles associated with property ownership.",
  },
  {
    q: "How can I get started with Santa Monica Property Management?",
    a: "Initiating our services is simple! Contact us through our website or give us a call. We will discuss your property management needs and outline how our expertise can effectively manage your property.",
  },
];

const FAQ = () => {
  useEffect(() => {
    // accordion
    $(".accordion").on("click", ".accordion_tab", function () {
      $(this).next().slideDown();
      $(".accordion_info").not($(this).next()).slideUp();
    });

    $(".accordion").on("click", ".item", function () {
      $(this).addClass("active").siblings().removeClass("active");
    });
  }, []);

  return (
    <>
      <PageHeader
        activeClass01="active"
        activeClass02="d-none"
        routeName01="FAQ"
        header="Frequently Asked Questions"
        subtitle={"Let's give you some answers"}
      />

      <div className="main_wrapper">
        <div className="faq">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="images-faq">
                  <img src="images/man.webp" alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <Box py={20}>
                  <div className="accordion">
                    {faqs.map(({ q, a }) => {
                      return (
                        <div className="item">
                          <div className="accordion_tab">
                            <h2 className="accordion_title">{q}</h2>
                            <span className="accordion_tab_icon">
                              <i className="open_icon ion-ios-arrow-down"></i>
                              <i className="close_icon ion-ios-arrow-up"></i>
                            </span>
                          </div>
                          <div className="accordion_info">{a}</div>
                        </div>
                      );
                    })}
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>

        <Banner
          title={SiteData.contact.closingText}
          heading="Call Us Anytime"
          phone={SiteData.phoneNumber}
          bannerType="banner banner_bg_color"
        />
      </div>
    </>
  );
};

export default FAQ;
