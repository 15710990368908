import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
  Text,
} from "@chakra-ui/react";

export const LeaseManagement = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"} fontSize="lg">
          Elevate Your Property with Proficient Lease Management
        </Heading>
        <Text>
          Mastery in handling the intricacies of lease agreements is a hallmark
          of Santa Monica Property Management. Our tailored lease management
          services aim to simplify your role as a property owner, ensuring every
          facet of lease handling is executed with professionalism and an
          unwavering eye for detail. We transform the complex landscape of lease
          management into a seamless operation, safeguarding your investment and
          unlocking the full potential of your property.
        </Text>
        <Text>
          Armed with an in-depth understanding of the Santa Monica real estate
          market and the nuances of property law, we provide a robust lease
          management framework that forms the bedrock for the successful and
          profitable operation of your rental property.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Holistic Drafting:</b> Our leases transcend mere documents; they
            embody a commitment to peace of mind. Crafted with expertise, they
            cover all facets to protect your interests and ensure a clear
            understanding of terms for tenants.
          </ListItem>
          <ListItem>
            <b>Vigilant Enforcement and Compliance:</b> We meticulously monitor
            compliance and enforce lease terms fairly and firmly. Addressing any
            violations promptly, we ensure that minor issues don’t escalate into
            more significant problems.
          </ListItem>
          <ListItem>
            <b>Strategic Renewals:</b> Our proactive approach to renewals
            anticipates tenant turnover, enabling us to act promptly to
            encourage continuous occupancy and maintain your revenue stream.
          </ListItem>
          <ListItem>
            <b>Ethical Terminations:</b> Whether a lease concludes naturally or
            unexpected circumstances necessitate termination, we manage the
            process ethically and in accordance with legal standards,
            safeguarding both you and your tenants.
          </ListItem>
        </UnorderedList>
        <Text>
          Unlock the full array of benefits with Santa Monica Property
          Management's lease management expertise. Our meticulous approach and
          unwavering commitment to client satisfaction position us as the top
          choice for property owners seeking comprehensive lease management
          solutions. Let's explore how our adept lease management can elevate
          the value and efficiency of your property management journey.
        </Text>
      </Stack>
    </Box>
  );
};
