import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../Layouts/HomeLayout";
import About from "../Pages/About";
import ContactPage from "../Pages/ContactPage";
import Home01 from "../Pages/Homes/Home01";
import Services01 from "../Pages/Services/Services01";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";
import Blog from "../Pages/Blog";
import Careers from "../Pages/Careers";
import React from "react";
import { BlogPost } from "../Layouts/BlogPost";
import TenantScreening from "../Pages/Services/TenantScreening";
import TenantRelations from "../Pages/Services/TenantRelations";
import RentCollection from "../Pages/Services/RentCollection";
import PropertyManagement from "../Pages/Services/PropertyManagement";
import PropertyMaintenance from "../Pages/Services/PropertyMaintenance";
import MarketingAndAdvertising from "../Pages/Services/MarketingAndAdvertising";
import LegalServices from "../Pages/Services/LegalServices";
import LeasingServices from "../Pages/Services/LeasingServices";
import FinancialReporting from "../Pages/Services/FinancialReporting";
import EmergencyResponse from "../Pages/Services/EmergencyResponse";
import EvictionServices from "../Pages/Services/EvictionServices";
import LosAngeles from "../Pages/ServiceAreas/los-angeles";
import PropertyManagementBeverlyHills from "../Pages/ServiceAreas/property-management-beverly-hills";
import PropertyManagementBurbank from "../Pages/ServiceAreas/property-management-burbank";
import HollywoodPropertyManagement from "../Pages/ServiceAreas/hollywood-property-management";
import KoreatownPropertyManagement from "../Pages/ServiceAreas/koreatown-property-management";
import LosFelizPropertyManagement from "../Pages/ServiceAreas/los-feliz-property-management";
import SantaMonicaPropertyManagement from "../Pages/ServiceAreas/santa-monica-property-management";
import SanFernandoValleyPropertyManagement from "../Pages/ServiceAreas/san-fernando-valley-property-management";
import Silverlake from "../Pages/ServiceAreas/silverlake";
import StudioCityPropertyManagement from "../Pages/ServiceAreas/studio-city-property-management";
import VenicePropertyManagement from "../Pages/ServiceAreas/venice-property-management";
import WestHollywoodPropertyManagement from "../Pages/ServiceAreas/west-hollywood-property-management";
import WestLosAngelesPropertyManagement from "../Pages/ServiceAreas/west-los-angeles-property-management";
import WestwoodPropertyManagement from "../Pages/ServiceAreas/westwood-property-management";
import CulverCityPropertyManagement from "../Pages/ServiceAreas/culver-city-property-management";
import EchoParkPropertyManagement from "../Pages/ServiceAreas/echo-park-property-management";
import Encinco from "../Pages/ServiceAreas/encinco";
import GlendalePropertyManagement from "../Pages/ServiceAreas/glendale-property-management";
import MidCityPropertyManagement from "../Pages/ServiceAreas/mid-city-property-management";
import NorthHollywoodPropertyManagement from "../Pages/ServiceAreas/north-hollywood-property-management";
import Pasadena from "../Pages/ServiceAreas/pasadena";
import ShermanOaks from "../Pages/ServiceAreas/sherman-oaks";
import Tarzana from "../Pages/ServiceAreas/tarzana";
import WoodlandHills from "../Pages/ServiceAreas/woodland-hills";
import FAQ from "../Pages/FAQ";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        path: "/",
        element: <Home01 />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/services",
        element: <Services01 />,
      },
      {
        path: "/tenant-screening",
        element: <TenantScreening />,
      },
      {
        path: "/tenant-relations",
        element: <TenantRelations />,
      },
      {
        path: "/rent-collection",
        element: <RentCollection />,
      },
      {
        path: "/property-management",
        element: <PropertyManagement />,
      },
      {
        path: "/property-maintenance",
        element: <PropertyMaintenance />,
      },
      {
        path: "/marketing-advertising",
        element: <MarketingAndAdvertising />,
      },
      {
        path: "/legal-services",
        element: <LegalServices />,
      },
      {
        path: "/leasing-services",
        element: <LeasingServices />,
      },
      {
        path: "/financial-reporting",
        element: <FinancialReporting />,
      },
      {
        path: "/emergency-response",
        element: <EmergencyResponse />,
      },
      {
        path: "/eviction-services",
        element: <EvictionServices />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/careers",
        element: <Careers />,
      },
      {
        path: "/contact",
        element: <ContactPage />,
      },
      {
        path: "/blog/:id",
        element: <BlogPost />,
      },
      {
        path: "/los-angeles",
        element: <LosAngeles />,
      },
      {
        path: "/property-management-beverly-hills",
        element: <PropertyManagementBeverlyHills />,
      },
      {
        path: "/property-management-burbank",
        element: <PropertyManagementBurbank />,
      },
      {
        path: "/hollywood-property-management",
        element: <HollywoodPropertyManagement />,
      },
      {
        path: "/koreatown-property-management",
        element: <KoreatownPropertyManagement />,
      },
      {
        path: "/los-feliz-property-management",
        element: <LosFelizPropertyManagement />,
      },
      {
        path: "/santa-monica-property-management",
        element: <SantaMonicaPropertyManagement />,
      },
      {
        path: "/san-fernando-valley-property-management",
        element: <SanFernandoValleyPropertyManagement />,
      },
      {
        path: "/silver-lake",
        element: <Silverlake />,
      },
      {
        path: "/studio-city-property-management",
        element: <StudioCityPropertyManagement />,
      },
      {
        path: "/venice-property-management",
        element: <VenicePropertyManagement />,
      },
      {
        path: "/west-hollywood-property-management",
        element: <WestHollywoodPropertyManagement />,
      },
      {
        path: "/west-los-angeles-property-management",
        element: <WestLosAngelesPropertyManagement />,
      },
      {
        path: "/westwood-property-management",
        element: <WestwoodPropertyManagement />,
      },
      {
        path: "/culver-city-property-management",
        element: <CulverCityPropertyManagement />,
      },
      {
        path: "/echo-park-property-management",
        element: <EchoParkPropertyManagement />,
      },
      {
        path: "/encino",
        element: <Encinco />,
      },
      {
        path: "/glendale-property-management",
        element: <GlendalePropertyManagement />,
      },
      {
        path: "/mid-city-property-management",
        element: <MidCityPropertyManagement />,
      },
      {
        path: "/north-hollywood-property-management",
        element: <NorthHollywoodPropertyManagement />,
      },
      {
        path: "/pasadena",
        element: <Pasadena />,
      },
      {
        path: "/sherman-oaks",
        element: <ShermanOaks />,
      },
      {
        path: "/tarzana",
        element: <Tarzana />,
      },
      {
        path: "/woodland-hills",
        element: <WoodlandHills />,
      },
      {
        path: "/faq",
        element: <FAQ />,
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
]);
