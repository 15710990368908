import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
  Text,
} from "@chakra-ui/react";

export const TenantScreening = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"} size="lg">
          Comprehensive Tenant Screening Services
        </Heading>
        <Text>
          Finding a reliable tenant is fundamental to the success of your real
          estate venture. At Santa Monica Property Management, we take pride in
          our meticulous tenant screening process, crafted to provide peace of
          mind and ensure a stable, rewarding rental experience for property
          owners in Santa Monica and beyond.
        </Text>
        <Text>
          Employing a blend of technology and personalized attention, we delve
          deep into the details to genuinely understand individuals who will
          inhabit your property. Our goal is to foster a community of
          responsible and respectful tenants, and it all starts with our
          rigorous and discerning screening practices, ensuring a harmonious
          living environment for your property.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Detailed Background Checks:</b> Our comprehensive checks leave no
            detail untouched, covering everything from prior rental history to
            public records. With meticulous scrutiny, we ensure that prospective
            tenants align with your standards, offering you peace of mind and
            confidence in the quality of occupants for your property.
          </ListItem>
          <ListItem>
            <b>Credit Evaluations:</b>Through a detailed examination of credit
            scores and financial history, we diligently assess the financial
            responsibility and capability of potential tenants. This rigorous
            process ensures that only individuals with a sound financial
            standing are considered, promoting a secure and stable leasing
            environment for your property.
          </ListItem>
          <ListItem>
            <b>Rigorous Employment Verification:</b> Through a meticulous
            examination of credit scores and financial history, we evaluate the
            financial responsibility and capability of potential tenants. This
            thorough review ensures that your property is entrusted to
            individuals who meet the highest standards, fostering a secure and
            stable leasing environment.
          </ListItem>
          <ListItem>
            <b>Thorough Reference Checks:</b> By contacting previous landlords
            and references, we acquire valuable insights into the applicant's
            behavior and reliability. This additional step enhances the
            screening process, providing an extra layer of confidence in
            ensuring that your property is entrusted to trustworthy and
            responsible tenants.
          </ListItem>
        </UnorderedList>
        <Text>
          Our tenant screening process transcends mere issue filtering; it's
          about shaping a community. We ensure that those moving into your
          property not only care for it but also contribute positively to the
          neighborhood's fabric. This holistic approach guarantees a harmonious
          living environment and fosters a sense of shared responsibility among
          tenants.
        </Text>
        <Text>
          By entrusting your tenant screening to Santa Monica Property
          Management, you're not just filling vacancies; you're cultivating a
          harmonious living environment and safeguarding your investment.
          Connect with us to discover how our thorough tenant screening can
          elevate the standard of your rental property.
        </Text>
      </Stack>
    </Box>
  );
};
