import React from "react";
import { SiteData as siteData, SiteData } from "../../Constants/siteData";
import {
  Box,
  Flex,
  Link,
  Stack,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer_above">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className=" ">
                <h2 className="widget_title">
                  {SiteData.homepage.footerHeading}
                </h2>
                <Stack
                  color={"white"}
                  shouldWrapChildren
                  spacing={2}
                  pr={{ base: 0, lg: 10 }}
                >
                  <Flex alignItems={"center"}>
                    <Text color={"white"}>
                      {SiteData.homepage.footerText()}
                    </Text>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Text color={"white"}>{SiteData.homepage.footerText2}</Text>
                  </Flex>
                </Stack>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer_widget footer_links">
                <h2 className="widget_title">Our Services</h2>
                <div className="footer_nav">
                  <Stack color={"white"} shouldWrapChildren spacing={2}>
                    {SiteData.homepage.featuredServices.map(
                      ({ path, name }) => {
                        return (
                          <Flex alignItems={"center"}>
                            <Link href={path} fontWeight={"600"}>
                              {name}
                            </Link>
                          </Flex>
                        );
                      }
                    )}
                  </Stack>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer_widget footer_links">
                <div className="footer_nav">
                  <ul className="footer_menu">
                    <Stack color={"white"} shouldWrapChildren spacing={1}>
                      <Flex alignItems={"center"}>
                        <Link href={"/los-angeles"} fontWeight={"600"}>
                          Los Angeles
                        </Link>
                      </Flex>
                      <Flex alignItems={"center"}>
                        <Link
                          href={"/property-management-beverly-hills"}
                          fontWeight={"600"}
                        >
                          Beverly Hills
                        </Link>
                      </Flex>
                      <Flex alignItems={"center"}>
                        <Link
                          href={"/property-management-burbank"}
                          fontWeight={"600"}
                        >
                          Burbank
                        </Link>
                      </Flex>
                      <Flex alignItems={"center"}>
                        <Link
                          href={"/hollywood-property-management"}
                          fontWeight={"600"}
                        >
                          Hollywood
                        </Link>
                      </Flex>
                      <Flex alignItems={"center"}>
                        <Link
                          href={"/koreatown-property-management"}
                          fontWeight={"600"}
                        >
                          Koreatown
                        </Link>
                      </Flex>
                      <Flex alignItems={"center"}>
                        <Link
                          href={"/los-feliz-property-management"}
                          fontWeight={"600"}
                        >
                          Los Feliz
                        </Link>
                      </Flex>
                      <Flex alignItems={"center"}>
                        <Link
                          href={"/santa-monica-property-management"}
                          fontWeight={"600"}
                        >
                          Santa Monica
                        </Link>
                      </Flex>
                      <Flex alignItems={"center"}>
                        <Link
                          href={"/san-fernando-valley-property-management"}
                          fontWeight={"600"}
                        >
                          San Fernando Valley
                        </Link>
                      </Flex>
                      <Flex alignItems={"center"}>
                        <Link href={"/silver-lake"} fontWeight={"600"}>
                          Silver Lake
                        </Link>
                      </Flex>
                      <Flex alignItems={"center"}>
                        <Link
                          href={"/studio-city-property-management"}
                          fontWeight={"600"}
                        >
                          Studio City
                        </Link>
                      </Flex>
                      <Flex alignItems={"center"}>
                        <Link
                          href={"/venice-property-management"}
                          fontWeight={"600"}
                        >
                          Venice
                        </Link>
                      </Flex>
                      <Flex alignItems={"center"}>
                        <Link
                          href={"/west-hollywood-property-management"}
                          fontWeight={"600"}
                        >
                          West Hollywood
                        </Link>
                      </Flex>
                    </Stack>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="footer_widget footer_links">
                <Stack
                  color={"white"}
                  shouldWrapChildren
                  spacing={1}
                  fontFamily={"inherit"}
                >
                  <Flex alignItems={"center"}>
                    <Link
                      href={"/west-los-angeles-property-management"}
                      fontWeight={"600"}
                    >
                      West Los Angeles
                    </Link>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Link
                      href={"/westwood-property-management"}
                      fontWeight={"600"}
                    >
                      Westwood
                    </Link>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Link
                      href={"/culver-city-property-management"}
                      fontWeight={"600"}
                    >
                      Culver City
                    </Link>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Link
                      href={"/echo-park-property-management"}
                      fontWeight={"600"}
                    >
                      Echo Park
                    </Link>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Link href={"/encino"} fontWeight={"600"}>
                      Encino
                    </Link>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Link
                      href={"/glendale-property-management"}
                      fontWeight={"600"}
                    >
                      Glendale
                    </Link>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Link
                      href={"/mid-city-property-management"}
                      fontWeight={"600"}
                    >
                      Mid City
                    </Link>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Link
                      href={"/north-hollywood-property-management"}
                      fontWeight={"600"}
                    >
                      North Hollywood
                    </Link>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Link href={"/pasadena"} fontWeight={"600"}>
                      Pasadena
                    </Link>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Link href={"/sherman-oaks"} fontWeight={"600"}>
                      Sherman Oaks
                    </Link>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Link href={"/tarzana"} fontWeight={"600"}>
                      Tarzana
                    </Link>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Link href={"/woodland-hills"} fontWeight={"600"}>
                      Woodland Hills
                    </Link>
                  </Flex>
                </Stack>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="footer_widget">
                {/*<h4 className="widget_title">*/}
                {/*    Address*/}
                {/*</h4>*/}
                <UnorderedList>
                  <ListItem>
                    <Link href={"/careers"} textTransform={"capitalize"}>
                      FAQ
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href={"/careers"} textTransform={"capitalize"}>
                      Careers
                    </Link>
                  </ListItem>
                  <ListItem pt={5}>
                    <i className="fa fa-clock-o" aria-hidden="true"></i>
                    <span>{SiteData.hoursOfOperation}</span>
                  </ListItem>
                </UnorderedList>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="container">
          <div className="footer_bottom_inner">
            <Box
              display={{ sm: "flex", base: "flex", lg: "initial" }}
              justifyContent={{ sm: "center", base: "center", lg: "initial" }}
              w={{ sm: "full", base: "full", lg: "initial" }}
              alignItems={"center"}
            >
              <div className="logo">
                <a href="index">
                  <img src="images/logo2.webp" alt="Logo" />
                </a>
              </div>
            </Box>
            <div className="copyright">
              <p>
                &copy; {new Date().getFullYear()} {siteData.footerText}
              </p>
            </div>
            <div className="totop">
              <a href="#" aria-label="Back to top">
                <i className="ion-ios-arrow-up"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
