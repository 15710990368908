import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const LeasingServices = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"} size="lg">
          Comprehensive Leasing Solutions for Santa Monica Real Estate
        </Heading>
        <Text>
          Recognizing that the leasing process is the foundation of successful
          property management, Santa Monica Property Management offers leasing
          services designed to streamline the entire rental cycle. From property
          listing to tenant move-out, our goal is to ensure that your real
          estate investment not only yields maximum returns but also involves
          minimal stress.
        </Text>
        <Text>
          Our team, equipped with local market expertise, legal acumen, and
          dedicated customer service, is committed to delivering excellence at
          every stage of the leasing process. With a focus on securing reliable
          tenants and maintaining high occupancy rates, our leasing strategies
          play a crucial role in the success of our clients in the dynamic Santa
          Monica market.
        </Text>
        <Heading as={"h3"} size="md">
          Our Range of Professional Leasing Services
        </Heading>
        <Text>
          Our comprehensive suite of leasing services covers every aspect of
          tenant acquisition and retention, ensuring a seamless and successful
          leasing process for property owners.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Targeted Property Marketing:</b> Utilizing both digital and
            traditional platforms, we strategically list your property on
            premier real estate websites, guaranteeing exposure to a wide yet
            targeted audience. This approach maximizes visibility and attracts
            potential tenants to your property.
          </ListItem>
          <ListItem>
            <b>Expert Lease Negotiation:</b> Our skilled negotiators work on
            your behalf to secure favorable lease terms, striking a balance
            between competitive pricing and the protection of your interests.
            This ensures a mutually beneficial agreement that optimizes both the
            financial aspects and safeguards your property investment.
          </ListItem>
          <ListItem>
            <b>Meticulous Contract Management:</b> We meticulously handle all
            leasing documentation, ensuring every contract meets current legal
            requirements and safeguards your rights as a property owner. Our
            thorough approach to documentation guarantees a secure and compliant
            leasing process for your peace of mind.
          </ListItem>
          <ListItem>
            <b>Streamlined Move-In/Move-Out Procedures:</b> Our efficient
            move-in and move-out processes encompass thorough property
            inspections and detailed documentation, preserving your property's
            condition and value. This meticulous approach ensures a smooth
            transition between tenants while maintaining the integrity of your
            investment.
          </ListItem>
        </UnorderedList>
        <Text>
          Our leasing services go beyond mere vacancy filling; we concentrate on
          fostering lasting relationships with tenants, starting with a seamless
          leasing experience. From the initial property showing to the final
          move-out walkthrough, Santa Monica Property Management ensures every
          interaction is professional, responsive, and conducive to long-term
          tenant engagement.
        </Text>
        <Text>
          Choose to partner with us for full-service leasing solutions. Our
          expertise in the Santa Monica property market allows us to exceed
          expectations, ensuring that your properties are not only efficiently
          leased but also managed with the utmost professionalism and attention
          to detail.
        </Text>
        <Text>
          Reach out to Santa Monica Property Management to discover how our
          tailored leasing services can elevate your property’s potential and
          contribute to your success as a property owner in Santa Monica.
        </Text>
      </Stack>
    </Box>
  );
};
