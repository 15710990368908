export const posts = [
    {
        "id": "1",
        "title": "Maximize Your Santa Monica Property Value with Local Expertise",
        "excerpt": "Discover how Property Management in Santa Monica can boost your property's value with our tailored management services in the Santa Monica area.",
        "slug": "maximize-santa-monica-property-value-local-expertise",
        "tags": ["Property Management", "Santa Monica", "Value Enhancement"],
        "metaDescription": "Learn how to enhance your property's value in Santa Monica with specialized management services from Property Management in Santa Monica.",
        "lastModified": new Date("2024-01-10").toISOString()
    },
    {
        "id": "2",
        "title": "Santa Monica Tenant Screening: Securing Your Investment",
        "excerpt": "Find out why effective tenant screening in Santa Monica is key to a secure and profitable property investment.",
        "slug": "santa-monica-tenant-screening-securing-investment",
        "tags": ["Tenant Screening", "Santa Monica", "Investment Security"],
        "metaDescription": "Explore the importance of thorough tenant screening in Santa Monica with Property Management in Santa Monica for a safe and prosperous investment.",
        "lastModified": new Date("2024-01-10").toISOString()
    },
    {
        "id": "3",
        "title": "Santa Monica Property Law: Expert Legal Services and Guidance",
        "excerpt": "Navigate Santa Monica's property law complexities, from rent control to tenant-landlord disputes, with our expert guidance.",
        "slug": "santa-monica-property-law-expert-legal-services",
        "tags": ["Legal Services", "Santa Monica Property Law", "Expert Consultation"],
        "metaDescription": "Tackle property law challenges in Santa Monica confidently with expert legal services offered by Property Management in Santa Monica.",
        "lastModified": new Date("2024-01-10").toISOString()
    },
    {
        "id": "4",
        "title": "Informed Property Ownership in Santa Monica with Transparent Reporting",
        "excerpt": "Gain control over your property finances in Santa Monica with our comprehensive and transparent financial reporting services.",
        "slug": "informed-property-ownership-santa-monica-transparent-reporting",
        "tags": ["Financial Reporting", "Santa Monica", "Transparency"],
        "metaDescription": "Ensure financial clarity and control in Santa Monica with detailed reporting from Property Management in Santa Monica, keeping you informed at every step.",
        "lastModified": new Date("2024-01-10").toISOString()
    }
];
