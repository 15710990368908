import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import {
  Box,
  Button,
  HStack,
  Image,
  Link,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import useBlogPosts from "../../Hooks/useBlogPosts";
import {
  createFacebookShareLink,
  formatDate,
  getBlogImage,
  shareThisBlogPostViaEmail,
  sortAndShowRecentPosts,
} from "../../Utils/metaHelpers";
import PageHeader from "../../Components/common/PageHeader";
import { BlogPost1 } from "../../Constants/blogPost1";
import towing8 from "../../assets/images/towing8.webp";
import towing9 from "../../assets/images/towing9.webp";
import towing1 from "../../assets/images/towing1.webp";
import towing6 from "../../assets/images/towing6.webp";
import { BlogPost2 } from "../../Constants/blogPost2";
import { BlogPost3 } from "../../Constants/blogPost3";
import { BlogPost4 } from "../../Constants/blogPost4";
import { posts } from "../../Constants/posts";
import { Share } from "../../Components/Share/Share";
import { useLocation } from "react-router-dom";
import { SiteData } from "../../Constants/siteData";
import { FaPhoneFlip } from "react-icons/fa6";
import { SEO } from "../../Components/SEO/SEO";

export const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Text fontWeight={"bold"}>{text}</Text>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <Image
            py={5}
            src={node.data?.target?.fields?.file?.url}
            alt={node.data?.target?.fields?.title}
        />
    ),
  },
};
const blogPostMapping = (id) => {
  switch (id) {
    case "1":
      return (
        <BlogPost1
          title={posts[0].title}
          img={towing8}
          subtitle={posts[0].excerpt}
        />
      );
    case "2":
      return (
        <BlogPost2
          title={posts[1].title}
          img={towing9}
          subtitle={posts[1].excerpt}
        />
      );
    case "3":
      return (
        <BlogPost3
          title={posts[2].title}
          img={towing1}
          subtitle={posts[2].excerpt}
        />
      );
    case "4":
      return (
        <BlogPost4
          title={posts[3].title}
          img={towing6}
          subtitle={posts[3].excerpt}
        />
      );
    default:
      break;
  }
};

const BlogDetails = ({ post }) => {
  const { posts } = useBlogPosts();
  const location = useLocation();
  const currentUrl = window.location.origin + location.pathname;
  return (
    <>
      <PageHeader header={post.title} />
      <SEO
        title={post.title}
        description={post.metaDescription}
        image={post.image}
      />
      <div className="main_wrapper section">
        <div className="container">
          <div className="">
            <div className="row">
              <div className="col-lg-8 col-md-12 pe-4">
                <div className="blog_details_inner">
                  <div className="post_content">
                    <Box mb={5}>
                      <HStack justify={"space-between"}>
                        <Tag colorScheme="blue">
                          {formatDate(post.lastModified)}
                        </Tag>
                        <Share
                          facebookUrl={createFacebookShareLink(currentUrl)}
                          emailUrl={shareThisBlogPostViaEmail(
                            currentUrl,
                            post.title
                          )}
                        />
                      </HStack>
                    </Box>
                    <Box
                      textAlign={"left"}
                      mb={5}
                      css={`
                        h3 {
                          margin: 1rem 0;
                          font-size: 1.4rem;
                          font-weight: bold;
                        }
                      `}
                    >
                      {Array.isArray(post.tags)
                        ? blogPostMapping(post.id)
                        : documentToReactComponents(post.body, options)}
                    </Box>
                    <Button
                      my={5}
                      size="lg"
                      bg={"rgba(7,44,87,0.9)"}
                      color={"white"}
                      rightIcon={<FaPhoneFlip />}
                      as={"a"}
                      href={SiteData.telLink}
                      _hover={{ textDecoration: "none" }}
                    >
                      Call to schedule a FREE consultation
                    </Button>
                    <div className="post_img">
                      <Image
                        src={post.image}
                        alt={post?.imageAltText || post?.title}
                        aspectRatio={3 / 2}
                        objectFit={"cover"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="sidebar">
                  <div id="recent-posts-1" className="widget">
                    <h4 className="widget_title">
                      Recent Posts
                      <span className="title_line"></span>
                    </h4>
                    <div className="sidebar_recent_posts">
                      <VStack spacing={5}>
                        {sortAndShowRecentPosts(posts, post).map((pst) => {
                          return (
                            <Box>
                              <Link
                                href={`/blog/${pst.slug}`}
                                _hover={{ color: "initial" }}
                              >
                                <VStack spacing={0} align={"left"}>
                                  <Image
                                    className="primary_img"
                                    src={pst.image || getBlogImage(pst.id)}
                                    objectFit={"cover"}
                                    alt={pst?.imageAltText || pst?.title}
                                    aspectRatio={3 / 2}
                                    mb={2}
                                  />
                                  <Text
                                    fontSize={"xl"}
                                    fontWeight={"bold"}
                                    color={"#051050"}
                                    mb={2}
                                  >
                                    {pst.title}
                                  </Text>
                                  <Box>
                                    <Tag mb={2}>
                                      {formatDate(pst.lastModified)}
                                    </Tag>
                                  </Box>
                                  <Text
                                    noOfLines={3}
                                    _hover={{ color: "initial" }}
                                  >
                                    {pst.excerpt}
                                  </Text>
                                </VStack>
                              </Link>
                            </Box>
                          );
                        })}
                      </VStack>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogDetails;
