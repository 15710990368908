import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const PropertyMaintenance = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"} size="lg">
          Holistic Property Maintenance Solutions
        </Heading>
        <Text>
          At Santa Monica Property Management, our dedication to preserving the
          integrity and allure of your property is unparalleled. Our proficient
          maintenance team is prepared to manage every facet of property care,
          ensuring your investment sustains its value and tenants experience a
          top-tier living environment. From the lush landscape that welcomes
          your guests to the seamless operation of every facility, we guarantee
          that your property exemplifies excellence.
        </Text>
        <Text>
          Recognizing that a well-maintained property is essential for tenant
          satisfaction and property appreciation, we provide a range of
          maintenance services tailored to address both aesthetic and functional
          needs. Our commitment is to uphold the overall condition of your
          property, ensuring it not only meets high standards but also enhances
          tenant experiences.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Landscaping Mastery:</b> Going beyond basic lawn care, our
            landscaping services encompass designing and maintaining an inviting
            outdoor space that mirrors the vibrant spirit of Santa Monica. We
            strive to ensure that your property consistently leaves a lasting
            impression, creating a welcoming environment that aligns with the
            distinctive charm of the local community.
          </ListItem>
          <ListItem>
            <b>Responsive Repairs:</b> Handling everything from minor repairs to
            major overhauls, our prompt maintenance team is prepared to address
            issues swiftly and efficiently. This proactive approach minimizes
            disruption for your tenants, preventing minor problems from
            escalating and ensuring the seamless functionality of your property.
          </ListItem>
          <ListItem>
            <b>Preventative Inspections:</b> Through regular and thorough
            inspections, we adopt a proactive approach to anticipate and address
            potential issues. This diligent property care strategy not only
            keeps unforeseen costs at bay but also extends the lifespan of your
            property's critical systems. Our commitment is to maintain the
            long-term integrity of your investment, ensuring a secure and
            cost-effective property management experience.
          </ListItem>
          <ListItem>
            <b>Strategic Vendor Partnerships:</b> Drawing on our longstanding
            relationships with trusted vendors, we secure top-notch services at
            competitive rates, ensuring that every aspect of your property is in
            capable hands. This network of reliable partners contributes to the
            seamless and efficient management of your property, providing you
            with high-quality services while optimizing costs.
          </ListItem>
        </UnorderedList>
        <Text>
          Allow Santa Monica Property Management to elevate your property
          maintenance to new heights. Our comprehensive services are tailored to
          meet the unique needs of your property, enabling you to enjoy the
          benefits of property ownership without the operational complexities.
          Reach out to us to explore how we can keep your property operating
          seamlessly and looking its best.
        </Text>
      </Stack>
    </Box>
  );
};
