import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
  Text,
} from "@chakra-ui/react";

export const PropertyManagement = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h1"} fontSize="xl">
          Premier Property Management Services in Santa Monica
        </Heading>
        <Text>
          Welcome to Santa Monica Property Management, your go-to solution for
          meticulous oversight and management of real estate investments in the
          vibrant heart of Santa Monica, CA. Renowned for reliability,
          efficiency, and unparalleled expertise, our property management
          services blend years of local market experience with innovative
          technology, offering a tailored service package to meet your
          investment's unique needs.
        </Text>
        <Heading as={"h2"} fontSize="lg">
          Comprehensive Property Management Solutions
        </Heading>
        <Text>
          Our extensive property management services aim to relieve the typical
          challenges associated with property ownership. Through a dedicated
          approach, we strive to enhance your property's value, secure maximum
          returns, and maintain a positive living experience for your tenants.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Detailed Tenant Screening:</b> Our meticulous tenant screening
            process involves a thorough evaluation of each candidate, including
            a comprehensive credit report, criminal background check, eviction
            history review, and verification of employment and rental history.
            This approach minimizes turnover rates and ensures a consistent
            revenue stream.
          </ListItem>
          <ListItem>
            <b>Proactive Property Maintenance:</b> Emphasizing preventative care
            and swift response to repair needs, our proactive maintenance
            approach, supported by a network of trusted contractors and in-house
            staff, ensures your property remains in pristine condition,
            preserving its value.
          </ListItem>
          <ListItem>
            <b>Efficient Rent Collection:</b> Streamlined rent collection
            through our secure online portal offers convenience to tenants and
            ensures a consistent cash flow for property owners. In case of late
            payments, we handle situations professionally, enforcing policies
            while maintaining positive tenant relations.
          </ListItem>
          <ListItem>
            <b>Legal Compliance and Documentation:</b> Keeping abreast of
            ever-changing property laws, we provide peace of mind by managing
            all legal aspects, including lease agreements, eviction procedures,
            and adherence to fair housing laws. Our comprehensive documentation
            ensures legal security and compliance with regulations.
          </ListItem>
          <ListItem>
            <b>Detailed Financial Reporting:</b> Knowledge is power in
            investment properties. Our financial reporting tools offer a clear
            picture of your property's financial status, providing transparency
            with 24/7 access to financial data.
          </ListItem>
        </UnorderedList>
        <Text>
          Beyond these key services, Santa Monica Property Management offers
          tailored solutions, including market analysis, strategic advertising,
          lease renewal negotiations, and comprehensive eviction support. Our
          goal is to provide a turnkey property management solution, allowing
          you to enjoy the benefits of ownership without operational
          complexities.
        </Text>
        <Text>
          Our commitment to excellence is evident in every interaction. From
          initial property assessment to ongoing management and reporting, we
          focus on achieving optimal performance for your real estate
          investment. Choose Santa Monica Property Management for a team that
          values integrity, transparency, and proactive communication.
        </Text>
        <Text>
          Experience the finest in property management services by contacting
          Santa Monica Property Management today. Our consultants are ready to
          offer a customized management plan aligned with your goals as a
          property owner in Santa Monica. Embrace the ease of professional
          property management and watch your investment thrive under our expert
          care.
        </Text>
        <Text>
          Reach out today to learn more about how we can transform your property
          management experience and elevate your Santa Monica property to new
          heights.
        </Text>
      </Stack>
    </Box>
  );
};
